@tailwind utilities;

@layer utilities {
  /* Media query for screens smaller than 470px wide */
  @media (max-width: 470px) {
    .bellow-470px-text-2xl {
      @apply text-2xl;
    }
  }

  /* Media query for screens smaller than 470px wide */
  @media (max-width: 370px) {
    .bellow-370px-text-xl {
      @apply text-xl;
    }
  }

  /* Media query for screens smaller than 470px wide */
  @media (max-width: 270px) {
    .bellow-270px-text-lg {
      @apply text-lg;
    }
  }
}
