@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #181c61;
}

@layer utilities {
  .header-text-Brand-Color {
    @apply text-[#181C61];
  }

  .header-text-Light-Brand-Color {
    @apply text-[#171941];
  }
  .text-Brand-Light-Brand-color {
    @apply text-[#787AA8];
  }
}
